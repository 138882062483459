import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PageContainerComponent } from '@core/layout/page-container/page-container.component';
import { CommonModule } from '@angular/common';
import {NavbarComponent} from "@core/layout/navbar/navbar.component";
import {FooterComponent} from "@core/layout/footer/footer.component";
import {TabsComponent} from "@core/layout/tabs-selector/tabs.component";

const components = [PageContainerComponent, NavbarComponent, FooterComponent, TabsComponent];
@NgModule({
  imports: [RouterModule, CommonModule, SharedModule],
  declarations: components,
  exports: components,
})
export class LayoutModule {}

import { Component } from '@angular/core';
import { EUserRole } from '@core/models/e-user-role';

@Component({
  selector: 'app-page-container',
  template: `
    <app-navbar></app-navbar>

    <div class="sidebar">
      <div class="sidebar-img">
        <a class="content" [routerLink]="['/']"> </a>
      </div>
    </div>

    <div class="bg-white flex-1 overflow-y-auto lg:w-3/5 lg:mx-auto">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  `,
  styles: [
    `
      :host {
        @apply h-screen w-screen flex flex-col lg:bg-neutral-100;
      }
    `,
  ],
})
export class PageContainerComponent {
  role = EUserRole;
}

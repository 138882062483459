import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EUserRole } from '@core/models/e-user-role';
import { logoutAction } from '../../../modules/user/store/auth.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '../../../modules/user/store/auth.state';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  isModalOpened: boolean = false;
  role = EUserRole;
  page: string = 'Home';
  private routerSubscription: Subscription;
  constructor(private router: Router, private store: Store<AuthState>) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url;
        this.updateStringBasedOnURL(currentUrl);
      }
    });
  }

  logout() {
    localStorage.setItem('access_token','');
    this.router.navigate(['/login']);
  }

  private updateStringBasedOnURL(currentUrl: string) {
    if (currentUrl.includes('sales')) {
      return (this.page = 'Vendita');
    }
    if (currentUrl.includes('appointments')) {
      return (this.page = 'Appuntamenti');
    }
    if (currentUrl.includes('purchases')) {
      return (this.page = 'Acquisti');
    }
    return 'Home';
  }
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}

<nav
  class="px-2 bg-white border-b border-gray h-16 dark:bg-gray-900 dark:border-gray-700">
  <div
    class="h-full container flex flex-wrap items-center justify-between mx-auto font-bold text-2xl px-2">
    {{page}}
    <button
      data-collapse-toggle="navbar-dropdown"
      type="button"
      class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden"
      aria-controls="navbar-dropdown"
      aria-expanded="false"
      (click)="isModalOpened = true">
      <svg
        class="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clip-rule="evenodd"></path>
      </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-dropdown">
      <ul
        class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <a
            href="#"
            class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent"
            aria-current="page"
            >Account</a
          >
        </li>
        <li (click)="logout()">
          <a
            href="#"
            class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent"
            aria-current="page"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-modal
  *ngIf="isModalOpened"
  [title]="'Menù'"
  [classes]="'w-2/3'"
  (closeModal)="isModalOpened = false">
  <div class="flex justify-center my-12">
    <button
      class="bg-primary text-white font-bold py-2 px-4 rounded"
      (click)="logout()">
      Logout
    </button>
  </div>
</app-modal>

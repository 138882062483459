<div class="bg-white h-20 border-t border-gray flex space">
  <div
    class="flex-1 flex flex-col justify-center items-center cursor-pointer"
    [routerLink]="['home/sales']">
    <img src="./assets/icons/vendita.svg" alt="vendita" />
    <div class="mt-2" routerLink="home/sales" routerLinkActive="text-primary font-bold">Vendita</div>
  </div>

  <div
    class="flex-1 flex flex-col justify-center items-center cursor-pointer"
    [routerLink]="['home/purchases']">
    <img src="./assets/icons/acquisto.svg" alt="acquisto" />
    <div class="mt-2" routerLink="home/purchases" routerLinkActive="text-primary font-bold">Acquisto</div>
  </div>

  <div
    class="flex-1 flex flex-col justify-center items-center cursor-pointer"
    [routerLink]="['home/appointments']">
    <img src="./assets/icons/appuntamento.svg" alt="appuntamento" />
    <div class="mt-2" routerLink="home/appointments" routerLinkActive="text-primary font-bold">App.</div>
  </div>

  <div
    class="flex-1 flex flex-col justify-center items-center cursor-pointer"
    [routerLink]="['home/calendar']">
    <img src="./assets/icons/calendario.svg" alt="calendario" />
    <div class="mt-2" routerLink="home/calendar" routerLinkActive="text-primary font-bold">Calendario</div>
  </div>

  <div
    class="flex-1 flex flex-col justify-center items-center cursor-pointer"
    [routerLink]="['home/files']">
    <img src="./assets/icons/file.svg" alt="file" />
    <div class="mt-2" routerLink="home/files" routerLinkActive="text-primary font-bold">File</div>
  </div>
</div>

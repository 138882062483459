import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-tabs',
  template: `
    <div class="rounded-xl flex justify-around overflow-hidden h-12">
      <div
        *ngFor="let button of tabs; let i = index"
        class="flex-1 text-center py-2 cursor-pointer"
        [ngClass]="
          i == activeTabIndex
            ? 'text-primary font-bold border-b-2 border-primary'
            : ''
        "
        (click)="onButtonClick(i)">
        {{ button.label }}
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() tabs: Array<{ label: string; value: string }> = [];
  @Input() activeTabIndex: number = 0;

  @Output() tabClicked: EventEmitter<{ label: string; value: string }> =
    new EventEmitter<{ label: string; value: string }>();

  onButtonClick(clickedButtonIndex: number) {
    this.activeTabIndex = clickedButtonIndex;
    this.tabClicked.emit(this.tabs[clickedButtonIndex]);
  }
}
